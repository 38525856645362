<script lang="ts" setup>
import {
	WELL_DONE,
	YOU_EARNED,
	WE_CREDITED_YOU,
	OKAY,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { computed } from 'vue';
import { transformCurrency } from '@/utils/helpers';
import modalImage from '@/assets/icons/stack-of-money.svg';
import type { OfferwallTransactionComplete } from '@/types';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { options } = defineProps<{
	options: OfferwallTransactionComplete;
}>();

defineEmits<{
	(e: 'close-modal'): void;
}>();

const { currency } = storeToRefs(useUserStore());

const reward = computed(() => {
	return `${transformCurrency(+options.money_earned || 0, currency.value, 2)}`;
});
</script>

<template>
	<ModalCommonContent class="completed-offer-modal text-center">
		<template #header>{{ $t(WELL_DONE) }}</template>
		<template #icon>
			<img :src="modalImage" alt="" />
		</template>
		<template #title>
			<PrimeText tag="div" weight="500">
				{{ $t(YOU_EARNED, { value: reward }) }}
			</PrimeText>
		</template>
		<template #message>
			<PrimeText tag="p" color="grey-700">
				{{ $t(WE_CREDITED_YOU, { value: reward, name: options.offer_name }) }}
			</PrimeText>
		</template>
		<template #footer>
			<PrimeButton
				type="text"
				variant="grey"
				rounded="md"
				:label="$t(OKAY)"
				@click="$emit('close-modal')"
			/>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.completed-offer-modal {
	:deep(.content-icon) {
		width: 12.5rem;
		height: 12.5rem;
	}
}
</style>
