<script lang="ts" setup>
import {
	COMPLETED_5_SURVEYS_MODAL_TITLE,
	COMPLETED_5_SURVEYS_MODAL_SUBTITLE,
	COMPLETED_5_SURVEYS_MODAL_MESSAGE,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { computed } from 'vue';
import { transformCurrency } from '@/utils/helpers';
import modalImage from '@/assets/icons/stack-of-money.svg';
import type { UserBalanceUpdateInfo } from '@/types';

const { options } = defineProps<{
	options: UserBalanceUpdateInfo;
}>();

const { isMobile } = useScreenSize();

const { currency } = storeToRefs(useUserStore());

const rewardText = computed(() => {
	return `${transformCurrency(+options.money_received || 0, currency.value, 2)}`;
});
</script>

<template>
	<ModalCommonContent class="completed-5-surveys-modal text-center">
		<template #header>{{ $t(COMPLETED_5_SURVEYS_MODAL_TITLE) }} 💸</template>
		<template #icon>
			<img :src="modalImage" alt="" />
		</template>
		<template #title>
			<PrimeText tag="div" :size="isMobile ? 'base' : '2xl'" weight="500">
				{{ $t(COMPLETED_5_SURVEYS_MODAL_SUBTITLE, { value: rewardText }) }} 💰
			</PrimeText>
		</template>
		<template v-if="options.transaction_type === 1" #message>
			<PrimeText tag="p" :size="isMobile ? 'sm' : 'base'" color="grey-700">
				{{ $t(COMPLETED_5_SURVEYS_MODAL_MESSAGE, { value: rewardText }) }}
			</PrimeText>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.completed-5-surveys-modal {
	@include breakpoint(mobile) {
		padding-bottom: 1.125rem;
	}

	:deep(.content-icon) {
		width: 12.5rem;
		height: 12.5rem;
	}
}
</style>
